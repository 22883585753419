import React from "react";
import { graphql } from "gatsby";

import MovieCarousel from "../../../components/MovieCarousel";
import MovieHero from "../../../components/MovieHero";
import ViewNext from "../../../components/ViewNext";
import MovieList from "../../../components/MovieList";
import HelmetInfo from "../../../components/HelmetInfo";

const InternationalTitles = ({ data }) => {
  return (
    <>
      <HelmetInfo page_data={data.prismicInternationalTitles.data} />
      <MovieCarousel items={data.prismicInternationalTitles.data.carousel} />
      <MovieHero
        heading={data.prismicInternationalTitles.data.heading}
        text={data.prismicInternationalTitles.data.description}
        buttons={data.prismicInternationalTitles.data.buttons}
      />
      <MovieList
        heading="What’s new"
        items={data.prismicInternationalTitles.data.whats_new_movies}
        buttonText="VIEW ALL"
        buttonLink="/distribution/screen-media/international-titles/whats-new"
      />
      <MovieList
        heading="Catalogue"
        items={data.prismicInternationalTitles.data.catalogue_movies}
        buttonText="VIEW ALL"
        buttonLink="/distribution/screen-media/international-titles/catalogue"
      />
      <ViewNext
        text="View Next Page"
        link={`/distribution/screen-media/us-syndication`}
        linkText="US Syndication"
        borderTop
        whiteBackground
      />
    </>
  );
};

export default InternationalTitles;

export const query = graphql`
  query InternationalTitlesQuery {
    prismicInternationalTitles {
      data {
        carousel {
          image {
            alt
            copyright
            url
          }
          button_text
          button_link {
            __typename
            uid
            url
          }
          image_heading
        }
        heading
        description {
          text
          html
          richText
        }
        buttons {
          button_link {
            __typename
            uid
            url
          }
          button_text
        }
        whats_new_movies {
          movie {
            document {
              ... on PrismicMovie {
                uid
                type
                __typename
                data {
                  title
                  genre
                  movie_poster {
                    alt
                    copyright
                    url
                  }
                }
              }
            }
          }
        }
        catalogue_movies {
          movie {
            document {
              ... on PrismicMovie {
                uid
                type
                __typename
                data {
                  title
                  genre
                  movie_poster {
                    alt
                    copyright
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
